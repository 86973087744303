import React, { useEffect, useState } from 'react'
import {
	Menu,
	MenuList,
	MenuButton,
	MenuLink,
	MenuItem,
} from '@reach/menu-button'
import { Link, graphql, useStaticQuery, navigate } from 'gatsby'
import { Lottie } from '@crello/react-lottie'
import * as animationSearch from '../animations/search-to-x.json'
import * as animationLock from '../animations/lock.json'
import { ChevronDown } from 'react-feather'
import tw, { css, styled } from 'twin.macro'

import filterLocale from './utils/filter-locale'
import getFlag from './utils/get-flag'
import useTheme from '../hooks/theme'
import useOkta from '../hooks/okta'
import useSearch from '../hooks/search'
import useLocale from '../hooks/useLocale'
const windowGlobal = typeof window !== 'undefined' && window
const isWindow = typeof window !== 'undefined'

/** Styles with Emotion & Tailwindcss */
const Bar = styled.header`
	${tw`flex flex-row flex-1`}
	background: linear-gradient(to right, #1B7DB3 0%,#2E8FB1 25%, #58c8df 70%, #83d2e4 100%);
`
const Wrapper = tw.div`flex flex-row flex-1 justify-between md:px-6 p-2`
const Nav = tw.nav`flex flex-row`
const MenuBar = styled.div`
	${tw`flex flex-row flex-1 justify-center items-stretch max-w-xl text-xs`}
	[data-reach-menu-button] {
		${tw`flex items-center ml-1 relative cursor-pointer`}
		${tw`text-white bg-gray-800 text-xs hover:bg-gray-700 hover:text-gray-100 font-normal font-sans rounded-full`}
		svg {
			path {
				${tw`stroke-current`}
				stroke-width: 3;
			}
		}
		span {
			${tw`ml-1 mr-2 mb-px`}
		}
		&.country {
			${tw`bg-gray-800 rounded-full pr-1 text-white hover:bg-gray-700`}
			svg {
				${tw`w-4 h-4`}
			}
			span {
				${tw`px-0`}
			}
		}
		a {
			${tw`flex`}
		}
	}
`
const Button = styled.button`
	${tw`flex items-center ml-1 relative cursor-pointer`}
	${tw`text-white bg-gray-800 text-xs hover:bg-gray-700 hover:text-gray-100 font-normal font-sans rounded-full`}
	.search,
	.login {
		${tw`bg-white text-gray-800 rounded-full`}
		padding: 3px;
		svg {
			path {
				${tw`stroke-current`}
				stroke-width: 3;
			}
		}
	}
	span {
		${tw`ml-1 mr-2 mb-px`}
	}
`
const StyledList = styled(MenuList)`
	${tw`bg-yellow-500 rounded py-2 px-5 mt-2`}
`
const StyledLink = styled(Link)`
	${tw`text-gray-800 hover:text-white`}
`
const StyledItem = styled.div`
	${tw`text-gray-800 hover:text-white`}
`
const Notification = styled.div`
	${tw`flex`}
	a {
		${tw`flex flex-row items-center text-xs font-sans`}
	}
`
const Text = tw.span`flex flex-col leading-none text-white m-0 font-semibold`
const Pill = tw.span`flex flex-col leading-none bg-white hover:bg-gray-100 text-gray-700 hover:text-gray-900 rounded-full px-2 py-px mr-1`
const Alert = tw.span`flex justify-center items-center text-white text-sm hidden sm:flex px-2 text-center`
const CountryFlag = styled.div`
	${tw`bg-white rounded-full pl-1 pt-1 w-5 h-5`}
	font-size: 11px;

	> img {
		width: 13px;
	}
`
const Country = styled.p`
	${tw`py-1 px-6 cursor-pointer hover:text-white hover:bg-mineral-teal`}

	&:not(:last-of-type) {
		${tw``}
	}
`
/**
 * Top Bar
 * @description 'Notifications on the left, business logic on the right'
 * @param {Object} props - The Props object
 */
const TopBar = () => {
	const { nav } = useStaticQuery(graphql`
		query getTopBar {
			nav: allContentfulNavMenu(
				filter: { contentful_id: { eq: "3ExLszQVFE521ovsI0GK6D" } }
			) {
				edges {
					node {
						node_locale
						ariaLabel
						navId
						menuItems {
							... on ContentfulNavButton {
								ariaLabel
								url
								primary
								icon
							}
							... on ContentfulNavDropDown {
								ariaLabel
								icon
								dropDownItems {
									... on ContentfulNavItem {
										title
										url
										icon
										image {
											file {
												url
											}
										}
									}
								}
							}
							... on ContentfulNavItem {
								title
								url
								icon
							}
						}
						message {
							messege
							pill
							url
						}
					}
				}
			}
		}
	`)
	const [{ play, open }, dispatch] = useSearch()
	const { logOut } = useOkta()
	const { locale, openSignIO } = useTheme()
	const { isAuthenticated } = useOkta()
	const [pathname, setPathName] = useState('')
	const [pathhash, setPathHash] = useState('')
	const [pathsearch, setPathSearch] = useState('')

	useEffect(() => {
		const path = windowGlobal.location.pathname
		const hash = windowGlobal.location.hash
		const search = windowGlobal.location.search
		setPathName(path)
		setPathHash(hash)
		setPathSearch(search)
	}, [])
	const { globalLocale, changeLocale } = useLocale(
		pathname,
		pathhash,
		pathsearch
	)
	/** Filter Array by locale */
	const [{ node }] = filterLocale(nav.edges, process.env.GATSBY_LOCALE)
	/** Destruct Filtered Array to Node keys */
	const { navId, ariaLabel, menuItems, message } = node
	/** Get Flag emoji by locale */
	const flag = getFlag(globalLocale)

	return (
		<Bar>
			<Wrapper>
				{message ? (
					<>
					<Notification>
						<a href="https://mydolphin.com.au/">
							<Pill>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 353.37 122.26"
									role="img"
									araia-label="elite*"
									tw="w-12"
								>
									<title>elite*</title>
									<path
										fill="#353e47"
										d="M287.46,48.16c-.76-.6-1.55-1.17-2.37-1.7a36.13,36.13,0,0,0-20-5.58h0c-11.22,0-21.35,3.57-28.94,9.88-8.5,7.06-13.83,17.56-13.83,30.52a44,44,0,0,0,.36,5.45c.06.48.14,1,.22,1.44.21,1.34.48,2.66.8,3.93.11.42.2.84.33,1.24a37.78,37.78,0,0,0,4,9.16c.17.27.35.54.52.82a38.87,38.87,0,0,0,2.57,3.5l.36.45A38.42,38.42,0,0,0,239,114l.47.31a41.11,41.11,0,0,0,4.33,2.52l0,0h0a47.37,47.37,0,0,0,21.19,4.81c12.37,0,24.89-5.54,32.49-15.21l-16.64-12.2h0c-.49.67-1,1.32-1.55,1.94-3.76,4.37-8.61,7.4-15.4,7.4-9.19,0-16-5.87-17.9-14.89h55.13V81.28C301.19,66.61,296.19,55.09,287.46,48.16Zm-41.4,24.4a15.71,15.71,0,0,1,5.16-10.43A17,17,0,0,1,262.85,58a14.25,14.25,0,0,1,14.58,14.57ZM150,32.32A13.72,13.72,0,0,0,158.77,8a13.72,13.72,0,0,0-18.89,19.82A13.7,13.7,0,0,0,150,32.32Zm-43.89,87.46h23.77V0H106.15ZM200.9,20H177.13V42.78h-39v77h23.76v-58h15.21v36.6c0,.06,0,.11,0,.17a30.14,30.14,0,0,0,.19,3.22c0,.36.1.7.15,1,.11.71.23,1.42.4,2.09.08.35.17.69.27,1,.19.66.4,1.3.64,1.91.11.28.21.56.33.83a18.61,18.61,0,0,0,1.15,2.22c0,.06.07.13.11.2a18.41,18.41,0,0,0,1.64,2.24c.09.1.19.18.27.28a18,18,0,0,0,1.64,1.63l.47.4a20.48,20.48,0,0,0,1.76,1.3l.48.31a23.56,23.56,0,0,0,2.27,1.25l.12.06h0a38.08,38.08,0,0,0,16.14,3.1c5.54,0,11.41-.47,16.64-2.37v-.08h0V99.5h0c-1.55,1.43-4.79,2-7.38,2.15-.86.06-1.65.08-2.29.08-10.29,0-11.25-6-11.25-14.26V61.79h20.92v-19H200.9Z"
									/>
									<path
										fill="#59c8df"
										d="M53.89,18.21C31.43,18.21,12.35,29.93,4.21,49A53.85,53.85,0,0,0,0,70.23c0,.26,0,.51,0,.76,0,1.73.09,3.44.26,5.1.06.59.18,1.14.25,1.72.18,1.32.36,2.65.62,3.92.11.53.27,1,.39,1.53.31,1.31.63,2.61,1,3.86.13.4.29.79.43,1.18C3.5,89.65,4,91,4.57,92.24c.12.27.26.53.39.79q1,2.07,2.09,4c.09.16.2.31.29.46a47.35,47.35,0,0,0,2.69,4,1.33,1.33,0,0,0,.14.19q1.55,2,3.27,3.88l0,0c9.85,10.52,24.24,16.65,40.42,16.65,13.82,0,26.84-4.35,37.64-15.11h0q2.17-2.18,4.25-4.7L78,89a43.89,43.89,0,0,1-5.61,5.79,30.92,30.92,0,0,1-19.31,7.53c-.58,0-1.12.11-1.71.11a33,33,0,0,1-3.46-.22c-8.85-1-16.29-5.77-20.26-13.46a28.37,28.37,0,0,1-2.92-9.87h75.57V72.09C100.34,37.61,81.55,18.21,53.89,18.21ZM27.5,50.63a23.39,23.39,0,0,1,7.94-9.47,25.7,25.7,0,0,1,14.94-4.37c12.8,0,21.41,6.27,24.16,16.58a27.8,27.8,0,0,1,1,7H24.77A33.13,33.13,0,0,1,27.5,50.63Z"
									/>
									<path
										fill="#fff"
										opacity="0.3"
										d="M199,21.91H179.08V44.73h-39v30.6q10-.3,19.86-.81V59.85h19.11V73.3c6.74-.49,13.38-1,19.87-1.7V59.85h20.92V44.73H199Zm66.11,20.92c-18.77,0-33.42,10.2-38.68,25.53q10.23-1.39,19.87-3c2.8-5.17,8.29-9.29,16.6-9.29A16.58,16.58,0,0,1,273.61,60q9.54-2.16,18.23-4.56C285.93,47.36,276.73,42.83,265.06,42.83ZM53.89,20.15C24.2,20.15,2.58,40.67,2,69.08a849.78,849.78,0,0,0,96.38,6.6V72.09C98.39,40.05,81.34,20.15,53.89,20.15ZM22.55,62.27l.29-2.2C24.9,44.27,35.2,34.84,50.38,34.84c16.51,0,26.92,9.75,27.13,25.45l0,2ZM150,30.37a11.84,11.84,0,1,0-11.83-11.84A11.85,11.85,0,0,0,150,30.37ZM108.11,75.74c1.15,0,2.29,0,3.45,0,5.52,0,11-.07,16.41-.16V2H108.11Z"
									/>
									<polygon
										fill="#59c8df"
										points="327.28 15.47 335.34 31.81 353.37 34.43 340.33 47.14 343.4 65.09 327.28 56.62 311.15 65.09 314.23 47.14 301.19 34.43 319.22 31.81 327.28 15.47"
									/>
									<path
										fill="#fff"
										opacity="0.32"
										d="M342.67,34.85,334,33.59l-6.76-13.72-6.77,13.72-15.14,2.19,11,10.68-.19,1.14A144.7,144.7,0,0,0,342.67,34.85Z"
									/>
								</svg>
							</Pill>
							<Text>MyDolphin</Text>
						</a>
					</Notification>
						<Alert>🛠️ 🛠️ 🛠️ 🛠️  Some forms will be temporarily unavailable due to scheduled maintenance on September 3, 2024. We apologize for any inconvenience and appreciate your patience. 🛠️ 🛠️ 🛠️ 🛠️</Alert>
						</>
				) : (
					''
				)}
				<Nav id={navId} role="navigation" aria-label={ariaLabel}>
					<MenuBar role="menubar">
						{menuItems.map((n, i) => {
							switch (n.ariaLabel) {
								case 'Search':
									return (
										<Button
											key={n.ariaLabel + `${i}`}
											role="menuitem"
											aria-haspopup={true}
											onClick={() =>
												dispatch({
													type: 'setState',
													state: { play: !play, open: !open, focus: false },
												})
											}
											onKeyDown={() =>
												dispatch({
													type: 'TOGGLE',
													toggle: { play: true, open: true },
												})
											}
										>
											<Lottie
												playingState={'playing'}
												speed={1}
												direction={play ? 1 : -1}
												width="20px"
												height="20px"
												className="lottie-container search"
												config={{
													animationData: animationSearch.default,
													loop: false,
													autoplay: true,
													renderer: 'svg',
												}}
												aria-hidden
											/>
											<span>{n.ariaLabel}</span>
										</Button>
									)
								case 'Country':
									return (
										<Menu key={i}>
											<MenuButton
												role="menuitem"
												aria-haspopup={true}
												className="country"
											>
												<CountryFlag aria-hidden>
													{n.dropDownItems.map((x, i) => {
														const icon = x.icon.toUpperCase()
														if (flag === icon) {
															return <img key={i} src={x.image.file.url} />
														}
													})}
												</CountryFlag>
												{n.dropDownItems.map((x, i) => {
													const icon = x.icon.toUpperCase()
													if (flag === icon) {
														return <span>{x.title}</span>
													}
												})}
												<ChevronDown />
											</MenuButton>
											<StyledList role="menu" aria-label="submenu">
												{n.dropDownItems.map((d, i) => {
													return (
														<Country
															onClick={() => {
																if (flag != d.icon.toUpperCase()) {
																	changeLocale(d.icon.toUpperCase())
																}
															}}
														>
															{d.title}
														</Country>
														// <MenuLink
														// 	key={i}
														// 	role="menuitem"
														// 	as={StyledLink}
														// 	to={'/'+d.url}
														// >
														// 	{d.title}
														// </MenuLink>
													)
												})}
											</StyledList>
										</Menu>
									)
								case 'Login':
									return ''
								/* if (!isAuthenticated()) {
										return (
											<Menu key={i}>
												<Button
													role="menuitem"
													onClick={openSignIO}
													onKeyDown={openSignIO}
												>
													<Lottie
														playingState={'stopped'}
														speed={0.2}
														direction={1}
														width="20px"
														height="20px"
														className="lottie-container login"
														config={{
															animationData: animationLock.default,
															loop: true,
															autoplay: false,
															renderer: 'svg',
														}}
														aria-hidden
													/>
													<span>Log In</span>
												</Button>
											</Menu>
										)
									} else {
										return (
											<Menu key={i}>
												<MenuButton role="menuitem">
													<Lottie
														playingState={'stopped'}
														speed={0.2}
														direction={-1}
														width="16px"
														height="16px"
														className="lottie-container login"
														config={{
															animationData: animationLock.default,
															loop: true,
															autoplay: false,
															renderer: 'svg',
														}}
														aria-hidden
													/>
													<span>Account</span>
												</MenuButton>
												<StyledList role="menu" aria-label="submenu">
													<MenuLink
														role="menuitem"
														as={StyledLink}
														to={'/account'}
													>
														Account
													</MenuLink>
													<MenuLink
														role="menuitem"
														as={StyledLink}
														to={'/account/profile'}
													>
														Profile
													</MenuLink>
													<MenuItem
														role="menuitem"
														as={StyledItem}
														onSelect={() => logOut()}
													>
														Logout
													</MenuItem>
												</StyledList>
											</Menu>
										)
									} */
								default:
									''
							}
						})}
					</MenuBar>
				</Nav>
			</Wrapper>
		</Bar>
	)
}

export default TopBar
